import React, { useEffect, useState } from "react";
import AdminLayout from "../component/AdminLayout/AdminLayout";
import { Chip } from "@mui/material";
import axios from "axios";
import { Avatar } from "@mui/material";
import { useParams, useNavigate } from "react-router-dom";
import gallary from "../assets/images/icons/gallery.svg";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function UpdateBlog() {
  const { id } = useParams();

  const navigate = useNavigate();

  const [old_thumbnail_image_path, setOldThumbnailImagePath] = useState("");
  const [old_main_image_path, setOldMainImagePath] = useState("");
  const [thumbnail_image, setThumbnailImage] = useState("");
  const [main_image, setMainImage] = useState("");
  const [blog_title, setBlogTitle] = useState("");
  const [blog_keywords, setBlogKeywords] = useState("");
  const [blog_thumbnail_description, setBlogDescription] = useState("");
  const [blog_main_description, setBlogLongDescription] = useState("");
  const [blog_release_date, setBlogReleaseDate] = useState("");
  const [game_category_id, setGameCategoryId] = useState("");
  const [game_category_name, setGameCategoryName] = useState("");
  const [game_id, setGameId] = useState("");

  useEffect(() => {
    const getDataById = async () => {
      const { data } = await axios.get(
        `https://coreyadmin.kgmmedia.co.uk/getsingleblog/${id}`
      );
      console.log(data);
      let thumbnail_image_path = data.thumbnail_image;
      let main_image_path = data.main_image;
      thumbnail_image_path = thumbnail_image_path.slice(7);
      main_image_path = main_image_path.slice(7);
      setOldThumbnailImagePath(thumbnail_image_path);
      setOldMainImagePath(main_image_path);
      setThumbnailImage(data.thumbnail_image);
      setMainImage(data.main_image);
      setBlogTitle(data.blog_title);
      setBlogKeywords(data.blog_keywords.slice(1, -1));
      setBlogDescription(data.blog_thumbnail_description);
      setBlogLongDescription(data.blog_main_description);
      setBlogReleaseDate(data.blog_release_date);
      setGameCategoryId(data.game_category_id);

      var new_id = data.game_category_id;

      let thumb_image = document.getElementById("thumb_img");
      thumb_image.src = `https://coreyadmin.kgmmedia.co.uk/${data.thumbnail_image}`;

      let main = document.getElementById("main_img");
      main.src = `https://coreyadmin.kgmmedia.co.uk/${data.main_image}`;

      getGameData(new_id);
    };

    const getGameData = async(id) => {  
      const { data } = await axios.get(
        `https://coreyadmin.kgmmedia.co.uk/getsingleblogcategoryusingid/${id}`
      );
      console.log(data);
      setGameCategoryName(data.game_category_name);
      setGameId(data.game_id);
    }

    getDataById();
  }, [id]);

  const updateBlog = (e) => {
    debugger;

    let formData = {};
    formData = new FormData();
    formData.append("old_thumbnail_image_path", old_thumbnail_image_path);
    formData.append("old_main_image_path", old_main_image_path);
    formData.append("thumbnail_image", thumbnail_image);
    formData.append("main_image", main_image);
    formData.append("game_category_id", game_category_id);
    formData.append("blog_title", blog_title);
    formData.append("blog_keywords", blog_keywords);
    formData.append("blog_thumbnail_description", blog_thumbnail_description);
    formData.append("blog_main_description", blog_main_description);
    formData.append("blog_release_date", blog_release_date);
    formData.append("user_id", localStorage.getItem("user_id"));

    e.preventDefault();
    axios
      .put(`https://coreyadmin.kgmmedia.co.uk/updateblog/${id}`, formData)
      .then((res) => {
        if (res.data.error) {
          toast.error(res.data.error, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.success("Blog is updated Successfully...", {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            navigate("/dashboard");
            window.location.reload();
          }, 2000);
        }
      });
    console.log(main_image);
  };

  let chipTags = [];

  chipTags = blog_keywords.split(", ");

  return (
    <>
      <AdminLayout lefttitle="add blog">
        <form action="">
          <div className="admin_div blog_div1">
            <div className="child child1">
              <div className="input_file_area">
                <input
                  type="file"
                  name=""
                  id=""
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                    let thumbImg = document.getElementById("thumb_img");
                    thumbImg.src = URL.createObjectURL(e.target.files[0]);
                    setThumbnailImage(e.target.files[0]);
                  }}
                />
                <div className="input_image_main">
                  <img
                    src={gallary}
                    alt=""
                    className="input_file_img img-fluid"
                    id="thumb_img"
                  />
                  <h5 className="input_desc mt-2 mt-md-3">
                    Add Thumbnail <br /> Images
                  </h5>
                  <p className="input_file_im_text mt-2 mt-md-4">
                    Minimum Size 270 x 200px
                  </p>
                </div>
              </div>
            </div>
            <div className="child child2">
              <div className="input_file_area">
                <input
                  type="file"
                  name=""
                  id=""
                  onChange={(e) => {
                    console.log(e.target.files[0]);
                    let mainImg = document.getElementById("main_img");
                    mainImg.src = URL.createObjectURL(e.target.files[0]);
                    setMainImage(e.target.files[0]);
                  }}
                />
                <div className="input_image_main">
                  <img
                    src={gallary}
                    alt=""
                    className="input_file_img img-fluid"
                    id="main_img"
                  />
                  <h5 className="input_desc mt-2 mt-md-3">Main Images</h5>
                  <p className="input_file_im_text_main mt-3 mt-md-4 text-center">
                    Minimum Size 270 x 200px
                  </p>
                </div>
              </div>
            </div>
            <div className="child child3">
              <div className="row">
                <div className="col-md-6">
                  <input
                    type="text"
                    className="d-block input_field_c w-100 border px-4"
                    value={game_id}
                    disabled={true}
                    placeholder="Enter Game Id"
                    onChange={(event) => {
                      setGameId(event.target.value);
                    }}
                  />
                  <p className="pt-2 pb-4 ps-3">
                    <span style={{ color: "red" }}>*</span>Required
                  </p>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className="input_field_c w-100 border px-4"
                    value={game_category_name}
                    disabled={true}
                    placeholder="Enter Game Category Name"
                    onChange={(event) => {
                      setGameCategoryName(event.target.value);
                    }}
                  />
                  <p className="pt-2 pb-4 ps-3">
                    <span style={{ color: "red" }}>*</span>Required
                  </p>
                </div>
              </div>
              <input
                type="text"
                className="input_field_c w-100 border mb-2 mb-md-4 px-4"
                placeholder="Enter Title"
                value={blog_title}
                onChange={(event) => {
                  setBlogTitle(event.target.value);
                }}
              />
              <input
                type="text"
                className="input_field_c w-100 border mb-2 mb-md-4 px-4"
                placeholder="Add Keywords tags"
                value={blog_keywords}
                onChange={(event) => {
                  setBlogKeywords(event.target.value);
                }}
              />
              <p className="pb-3">
                <span className="fw-bold ps-3 pe-2">Note:</span> To enter the
                multiple keywords, split them with (comma) plus (space).
              </p>
              <div
                className={
                  blog_keywords === ""
                    ? "d-none"
                    : "d-block border rounded mb-3 py-4 px-3"
                }
              >
                {chipTags.map((data) => (
                  <>
                    <Chip
                      avatar={<Avatar>{data[0]}</Avatar>}
                      label={data}
                      variant="outlined"
                      className="me-2"
                    />
                  </>
                ))}
              </div>
              <textarea
                name=""
                id=""
                cols="30"
                rows="5"
                className="input_field_c w-100 border mb-2 mb-md-4 px-4"
                placeholder="Enter Description"
                value={blog_thumbnail_description}
                onChange={(event) => {
                  setBlogDescription(event.target.value);
                }}
              />
              <textarea
                name=""
                id=""
                cols="30"
                rows="7"
                className="input_field_c w-100 border mb-2 mb-md-4 px-4"
                placeholder="Enter Long Description"
                value={blog_main_description}
                onChange={(event) => {
                  setBlogLongDescription(event.target.value);
                }}
              />
              <input
                type="text"
                className="input_field_c w-100 border mb-2 mb-md-4 px-4"
                placeholder="Enter Blog Release Date (yyyy-mm-dd)"
                value={blog_release_date}
                onChange={(event) => {
                  const pattern = /^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/;
                  if (pattern.test(event.target.value)) {
                    setBlogReleaseDate(event.target.value);
                  } else {
                    setBlogReleaseDate(null);
                  }
                }}
              />
              <div className="text-center text-md-end mt-2 mt-md-3">
                <button
                  className="btn_one py-2 px-3 py-md-3 px-md-5 btn_w_100"
                  onClick={updateBlog}
                >
                  Update
                </button>
                <ToastContainer />
              </div>
            </div>
          </div>
        </form>
      </AdminLayout>
    </>
  );
}

export default UpdateBlog;
