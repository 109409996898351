import React from 'react';

export default function AdminHeader(props) {
  const admin_name= localStorage.getItem("firstName") + ' ' +localStorage.getItem("lastName")
  const admin_img = `https://coreyadmin.kgmmedia.co.uk/${localStorage.getItem("image")}`;

  return (
    <>
      <div className="admin_header">
        <div className="admin_header_inner">
          <h1>{props.title}</h1>
          <div className='d-flex gap-4 align-items-center'>
            <div>
                <p>Welcome</p>
                <h4 className='mt-1'>{admin_name}</h4>
            </div>
            <img src={admin_img} className="adminImg" alt="" />
          </div>
        </div>
      </div>
    </>
  );
}
