import React, { useEffect, useState } from "react";
import AdminLayout from "../component/AdminLayout/AdminLayout";
import editIcon from "../assets/images/icons/edit_grey.svg";
import userIcon from "../assets/images/icons/userIcon.svg";
import sms from "../assets/images/icons/sms.svg";
import call from "../assets/images/icons/call.svg";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function AccountSetting() {
  const navigate = useNavigate();

  const old_profile_image_path = localStorage.getItem("image").slice(7);
  const [firstname, setFirstName] = useState(localStorage.getItem("firstName"));
  const [lastname, setLastName] = useState(localStorage.getItem("lastName"));
  const [username, setUserName] = useState(localStorage.getItem("userName"));
  const [phonenumber, setPhoneNumber] = useState(
    localStorage.getItem("phoneNumber")
  );
  const [profileimage, setProfileImage] = useState("");

  useEffect(() => {
    debugger;
    let img = document.getElementById("img");
    if (localStorage.getItem("image") !== "" )  
    {
      setProfileImage(localStorage.getItem("image"));
      img.src = `https://coreyadmin.kgmmedia.co.uk/${localStorage.getItem("image")}`;
    }
  }, []);

  const handleImageUpload = (e) => {
    console.log(e.target.files[0]);
    setProfileImage(e.target.files[0]);
    let img = document.getElementById("img");
    img.src = URL.createObjectURL(e.target.files[0]);
  };

  const updateProfile = () => {
    debugger;
    let formData = {};
    if (typeof profileimage === "string") {
      formData["firstname"] = firstname;
      formData["lastname"] = lastname;
      formData["username"] = username;
      formData["phonenumber"] = phonenumber;
      formData["profileimage"] = profileimage;
      formData["old_profile_image_path"] = old_profile_image_path;
    } else {
      formData = new FormData();
      formData.append("firstname", firstname);
      formData.append("lastname", lastname);
      formData.append("username", username);
      formData.append("phonenumber", phonenumber);
      formData.append("profileimage", profileimage);
      formData.append("old_profile_image_path", old_profile_image_path);
    }

    const uid = localStorage.getItem("user_id");
    axios
      .put(`https://coreyadmin.kgmmedia.co.uk/changeuser/${uid}`, formData)
      .then((res) => {
        if (res.data.error) {
          toast.error(res.data.error, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          const apiData = res.data;
          Object.keys(apiData).forEach((key) => {
            localStorage.setItem(key, apiData[key]);
          });

          toast.success("Profile is updated Successfully...", {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            navigate("/dashboard");
          }, 2000);
        }
      });
  };

  return (
    <AdminLayout lefttitle="Account Setting">
      <div className="admin_div">
        <div className="text-center">
          <div className="userIcon_area w-50">
            <img
              src={userIcon}
              id="img"
              className="userIcon img-fluid"
              alt=""
            />
            <p className="para_two text-dark-grey text-decoration-underline mt-3">
              Add Profile Image
            </p>
            <input
              type="file"
              name=""
              id=""
              className="user_input_file"
              onChange={handleImageUpload}
            />
          </div>
        </div>

        <div className="row mt-3 mt-md-5 g-4">
          <div className="col-md-6">
            <div className="position-relative">
              <input
                type="text"
                className="input_field_c w-100 border"
                placeholder="First Name"
                value={firstname}
                onChange={(event) => {
                  setFirstName(event.target.value);
                }}
              />
              <img src={editIcon} className="input_icon_left" alt="" />
            </div>
          </div>

          <div className="col-md-6">
            <div className="position-relative">
              <input
                type="text"
                className="input_field_c w-100 border"
                placeholder="Last Name"
                value={lastname}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />
              <img src={editIcon} className="input_icon_left" alt="" />
            </div>
          </div>

          <div className="col-md-6">
            <div className="position-relative">
              <input
                type="email"
                className="input_field_c w-100 border"
                placeholder="Email"
                value={username}
                onChange={(event) => {
                  setUserName(event.target.value);
                }}
              />
              <img src={sms} className="input_icon_left" alt="" />
            </div>
          </div>

          <div className="col-md-6">
            <div className="position-relative">
              <input
                type="number"
                className="input_field_c w-100 border"
                placeholder="Phone"
                value={phonenumber}
                onChange={(event) => {
                  setPhoneNumber(event.target.value);
                }}
              />
              <img src={call} className="input_icon_left" alt="" />
            </div>
          </div>
        </div>
        <div className="text-center text-md-end mt-4 mt-md-5">
          <input
            type="submit"
            value="Update"
            className="btn_one py-2 px-3 py-md-3 px-md-5 btn_w_100"
            onClick={updateProfile}
          />
          <ToastContainer />
        </div>
      </div>
    </AdminLayout>
  );
}

export default AccountSetting;
