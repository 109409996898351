import React, { useEffect, useState } from "react";
import editIcon from "../assets/images/icons/edit.svg";
import delIcon from "../assets/images/icons/delete.svg";
import { Link } from "react-router-dom";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function BlogCard() {
  const [listOfBlogs, setlistOfBlogs] = useState([]);

  useEffect(() => {
    const getBlogsData = () => {
      const { data } = axios.get(`https://coreyadmin.kgmmedia.co.uk/getadminblog/${localStorage.getItem('user_id')}`);
      setlistOfBlogs(data);
      console.log(data);
      localStorage.setItem(`blogsLength`, data.length);
    };
    getBlogsData();
  }, []);

  const deleteBlog = (id) => {
    console.log(id);
    debugger;

    axios.delete(`https://coreyadmin.kgmmedia.co.uk/deleteblog/${id}`).then((res) => {
      if (res.data.error) {
        toast.error(res.data.error, { 
          position: toast.POSITION.TOP_CENTER
        });
      } else {
        toast.success("Blog is deleted Successfully...", {
          position: toast.POSITION.TOP_CENTER
        });
        setTimeout(() => {
          window.location.reload();
         }, 2000);  
      }
    });
  };

  // const allData = [
  //   {
  //     id: "1",
  //     img: require("../assets/images/jumanji.png"),
  //     title: "Jumanji World",
  //     desc: "  Will you play the game, or will the game play you? Find out in JUMANJI: The Video Game, Coming November 15th to PlayStation 4.",
  //   },
  //   {
  //       id: "2",
  //       img: require("../assets/images/shadowFIght3.png"),
  //       title: "Shadow Fight 3",
  //       desc: "  Will you play the game, or will the game play you? Find out in JUMANJI: The Video Game, Coming November 15th to PlayStation 4.",
  //     },
  // ];

  return (
    <>
      {listOfBlogs.length > 0 && listOfBlogs.map((data, i) => (
        <>
          <ToastContainer />
          <div className="blog_card">
            <img
              src={`https://coreyadmin.kgmmedia.co.uk/${data.thumbnail_image}`}
              className="blog_card_img"
              alt=""
            />
            <div>
              <h1 className="para_one fw-bold">{data.blog_title}</h1>
              <h4 className="para_two mt-2" style={{ color: "#8F8F8F" }}>
                {data.blog_thumbnail_description}
              </h4>
              <div className="dbl_btn mt-4">
                <Link to={`/updateblog/${data.blog_id}`} className="w-100">
                  <button>
                    <img src={editIcon} alt="" /> Edit
                  </button>
                </Link>
                <button
                  onClick={() => {
                    deleteBlog(data.blog_id);
                  }}
                >
                  <img src={delIcon} alt="" /> Delete
                </button>
              </div>
            </div>
          </div>
        </>
      ))}
    </>
  );
}

export default BlogCard;
