import React, { useEffect, useState } from "react";
import AdminLayout from "../component/AdminLayout/AdminLayout";
import { Chip } from "@mui/material";
import axios from "axios";
import { Avatar } from "@mui/material";
import gallary from "../assets/images/icons/gallery.svg";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function Blog() {
  const navigate = useNavigate();

  const [game_category_id, setGameCategoryId] = useState("");
  const [game_category_name, setGameCategoryName] = useState("");
  const [game_id, setGameId] = useState("");
  const [frontend_game_id, setFrontGameId] = useState("");

  const [thumbnail_image, setThumbnailImage] = useState("");
  const [main_image, setMainImage] = useState("");
  const [blog_title, setBlogTitle] = useState("");
  const [blog_keywords, setBlogKeywords] = useState("");
  const [blog_thumbnail_description, setBlogDescription] = useState("");
  const [blog_main_description, setBlogLongDescription] = useState("");
  const [blog_release_date, setBlogReleaseDate] = useState("");

  console.log(game_id);

  useEffect(() => {
    const getBlogsData = async () => {
      if (sessionStorage.getItem("game_id") !== null) {
        const { data } = await axios.get(
          `https://coreyadmin.kgmmedia.co.uk/getsingleblogcategory/${sessionStorage.getItem(
            "game_id"
          )}`
        );
        if (data) {
          setGameCategoryId(data.game_category_id);
          setGameCategoryName(data.game_category_name);
          setGameId(data.game_id)
        }
      }
    };
    getBlogsData();
  }, []);

  const blog = async (e) => {
    const formData = new FormData();

    formData.append("thumbnail_image", thumbnail_image);
    formData.append("main_image", main_image);
    formData.append("game_category_id", game_category_id);
    formData.append("blog_title", blog_title);
    formData.append("blog_keywords", blog_keywords);
    formData.append("blog_thumbnail_description", blog_thumbnail_description);
    formData.append("blog_main_description", blog_main_description);
    formData.append("blog_release_date", blog_release_date);
    formData.append("user_id", localStorage.getItem("user_id"));

    e.preventDefault();

    const datepattern = /^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/;
    if (
      !(thumbnail_image instanceof File) ||
      !(main_image instanceof File) ||
      blog_title === "" ||
      blog_keywords === "" ||
      blog_thumbnail_description === "" ||
      blog_main_description === "" ||
      blog_release_date === ""
    ) {
      toast.error("Kindly Add Correct Value", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else if (!datepattern.test(blog_release_date)) {
      toast.error("Kindly Add Date with YYYY-MM-DD Format", {
        position: toast.POSITION.TOP_CENTER,
      });
    } else {
      axios.post("https://coreyadmin.kgmmedia.co.uk/postblogs", formData).then((res) => {
        if (res.data.error) {
          toast.error(res.data.error, {
            position: toast.POSITION.TOP_CENTER,
          });
        } else {
          toast.success("Blog is posted Successfully...", {
            position: toast.POSITION.TOP_CENTER,
          });
          setTimeout(() => {
            navigate("/dashboard");
            window.location.reload();
          }, 2000);
          sessionStorage.removeItem("game_id");
        }
      });
    }
  };

  let chipTags = [];

  chipTags = blog_keywords.split(", ");

  const search = async (key) => {
    const game_id_pattern = /^[0-9]*$/;

    if (game_id_pattern.test(key)) {
      const { data } = await axios.get(
        `https://coreyadmin.kgmmedia.co.uk/getsingleblogcategory/${key}`
      );
      if (data) {
        setGameCategoryId(data.game_category_id);
        setGameId(data.game_id);
        setGameCategoryName(data.game_category_name);
        toast.success(
          data.game_id +
            " " +
            " - " +
            data.game_category_name +
            " " +
            "successfully found",
          {
            position: toast.POSITION.TOP_CENTER,
          }
        );
      } else {
        toast.error("Game Category is not found!!!", {
          position: toast.POSITION.TOP_CENTER,
        });
        sessionStorage.setItem(`game_id`, key);
        setTimeout(() => {
          navigate("/postblogCategory");
        }, 2000);
      }
    } else {
      toast.error("Kindly enter numeric id!!!", {
        position: toast.POSITION.TOP_CENTER,
      });
    }
  };

  return (
    <>
      <AdminLayout lefttitle="add blog">
        <form action="">
          <div className="admin_div blog_div1">
            <div className={game_id === "" ? "d-none" : "d-block child child1"}>
              <div className="input_file_area">
                <input
                  type="file"
                  name=""
                  id=""
                  onChange={(e) => {
                    setThumbnailImage(e.target.files[0]);
                    let thumbImg = document.getElementById("thumb_img");
                    thumbImg.src = URL.createObjectURL(e.target.files[0]);
                  }}
                />
                <div className="input_image_main">
                  <img
                    src={gallary}
                    alt=""
                    className="input_file_img img-fluid"
                    id="thumb_img"
                  />
                  <h5 className="input_desc mt-2 mt-md-3">
                    Add Thumbnail <br /> Images
                  </h5>
                  <p className="input_file_im_text mt-2 mt-md-4">
                    Minimum Size 270 x 200px
                  </p>
                </div>
              </div>
              <p className="pt-2 ps-3">
                <span style={{ color: "red" }}>*</span>Required
              </p>
            </div>
            <div className={game_id === "" ? "d-none" : "d-block child child2"}>
              <div className="input_file_area">
                <input
                  type="file"
                  name=""
                  id=""
                  onChange={(e) => {
                    setMainImage(e.target.files[0]);
                    let mainImg = document.getElementById("main_img");
                    mainImg.src = URL.createObjectURL(e.target.files[0]);
                  }}
                />
                <div className="input_image_main">
                  <img
                    src={gallary}
                    alt=""
                    className="input_file_img img-fluid"
                    id="main_img"
                  />
                  <h5 className="input_desc mt-2 mt-md-3">Main Images</h5>
                  <p className="input_file_im_text_main mt-3 mt-md-4 text-center">
                    Minimum Size 270 x 200px
                  </p>
                </div>
              </div>
              <p className="pt-2 ps-3">
                <span style={{ color: "red" }}>*</span>Required
              </p>
            </div>
            <div className="child child3">
              <input
                type="text"
                className={
                  game_id !== ""
                    ? "d-none"
                    : "d-block input_field_c w-100 border px-4"
                }
                placeholder="Search Game Id"
                onChange={(event) => {
                  setFrontGameId(parseInt(event.target.value));
                }}
              />
              <p className={game_id !== "" ? "d-none" : "d-block pt-2 ps-3"}>
                <span style={{ color: "red" }}>*</span>Required
              </p>
              <button
                type="button"
                class={
                  game_id !== ""
                    ? "d-none"
                    : "d-block btn btn-primary my-2 me-4 float-end"
                }
                onClick={() => {
                  search(frontend_game_id);
                }}
              >
                Search
              </button>
              <div className="row">
                <div className="col-md-6">
                  <input
                    type="text"
                    className={
                      game_id === ""
                        ? "d-none"
                        : "d-block input_field_c w-100 border px-4"
                    }
                    value={game_id}
                    disabled={true}
                    placeholder="Enter Game Id"
                    onChange={(event) => {
                      setGameId(event.target.value);
                    }}
                  />
                  <p
                    className={
                      game_id === "" ? "d-none" : "d-block pt-2 pb-4 ps-3"
                    }
                  >
                    <span style={{ color: "red" }}>*</span>Required
                  </p>
                </div>
                <div className="col-md-6">
                  <input
                    type="text"
                    className={
                      game_id === ""
                        ? "d-none"
                        : "d-block input_field_c w-100 border px-4"
                    }
                    value={game_category_name}
                    disabled={true}
                    placeholder="Enter Game Category Name"
                    onChange={(event) => {
                      setGameCategoryName(event.target.value);
                    }}
                  />
                  <p
                    className={
                      game_id === "" ? "d-none" : "d-block pt-2 pb-4 ps-3"
                    }
                  >
                    <span style={{ color: "red" }}>*</span>Required
                  </p>
                </div>
              </div>
              <input
                type="text"
                className={
                  game_id === ""
                    ? "d-none"
                    : "d-block input_field_c w-100 border px-4"
                }
                placeholder="Enter Title"
                onChange={(event) => {
                  setBlogTitle(event.target.value);
                }}
              />
              <p
                className={game_id === "" ? "d-none" : "d-block pt-2 pb-2 ps-3"}
              >
                <span style={{ color: "red" }}>*</span>Required
              </p>
              <input
                type="text"
                className={
                  game_id === ""
                    ? "d-none"
                    : "d-block input_field_c w-100 border px-4"
                }
                placeholder="Add Keywords tags"
                onChange={(event) => {
                  setBlogKeywords(event.target.value);
                }}
              />
              <p
                className={game_id === "" ? "d-none" : "d-block pt-2 pb-2 ps-3"}
              >
                <span style={{ color: "red" }}>*</span>Required
              </p>
              <p className={game_id === "" ? "d-none" : "d-block pt-2 pb-4"}>
                <span className="fw-bold ps-3 pe-2">Note:</span> To enter the
                multiple keywords, split them with (comma) plus (space).
              </p>
              <div
                className={
                  blog_keywords === ""
                    ? "d-none"
                    : "d-block border rounded mb-3 py-4 px-3"
                }
              >
                {chipTags.map((data) => (
                  <>
                    <Chip
                      avatar={<Avatar>{data[0]}</Avatar>}
                      label={data}
                      variant="outlined"
                      className="me-2"
                    />
                  </>
                ))}
              </div>
              <textarea
                name=""
                id=""
                cols="30"
                rows="5"
                className={
                  game_id === ""
                    ? "d-none"
                    : "d-block input_field_c w-100 border px-4"
                }
                placeholder="Enter Description"
                onChange={(event) => {
                  setBlogDescription(event.target.value);
                }}
              />
              <p
                className={game_id === "" ? "d-none" : "d-block pt-2 pb-2 ps-3"}
              >
                <span style={{ color: "red" }}>*</span>Required
              </p>
              <textarea
                name=""
                id=""
                cols="30"
                rows="7"
                className={
                  game_id === ""
                    ? "d-none"
                    : "d-block input_field_c w-100 border px-4"
                }
                placeholder="Enter Long Description"
                onChange={(event) => {
                  setBlogLongDescription(event.target.value);
                }}
              />
              <p
                className={game_id === "" ? "d-none" : "d-block pt-2 pb-2 ps-3"}
              >
                <span style={{ color: "red" }}>*</span>Required
              </p>
              <input
                type="text"
                className={
                  game_id === ""
                    ? "d-none"
                    : "d-block input_field_c w-100 border px-4"
                }
                placeholder="Enter Blog Release Date (yyyy-mm-dd)"
                onChange={(event) => {
                  const pattern = /^(\d{4})(\/|-)(\d{1,2})(\/|-)(\d{1,2})$/;
                  if (pattern.test(event.target.value)) {
                    setBlogReleaseDate(event.target.value);
                  } else {
                    setBlogReleaseDate(null);
                  }
                }}
              />
              <p
                className={game_id === "" ? "d-none" : "d-block pt-2 pb-2 ps-3"}
              >
                <span style={{ color: "red" }}>*</span>Required
              </p>
              <div className="mt-2 mt-md-3 w-100 clearfix">
                <button
                  className={
                    game_id === ""
                      ? "d-none"
                      : "d-block btn_one py-2 px-3 py-md-3 px-md-5 btn_w_100 mx-md-0 mx-auto float-md-end"
                  }
                  onClick={blog}
                >
                  Add
                </button>
                <ToastContainer />
              </div>
            </div>
          </div>
        </form>
      </AdminLayout>
    </>
  );
}

export default Blog;
