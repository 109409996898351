import React, { useEffect, useState } from "react";
// Import Swiper React components
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper styles
import "swiper/css";

// import img from "../../assets/images/slider1.png";
import SlideNextButton from "./SlideNextButton";
import SlidePrevButton from "./SlidePrevButton";
import axios from "axios";

function Slider() {
  const [listOfSliders, setListOfSliders] = useState([]);

  useEffect(() => {
    const getBlogsData = async () => {
      const { data } = await axios.get("https://coreyadmin.kgmmedia.co.uk/getmainslider");
      setListOfSliders(data);
      console.log(data);
    };
    getBlogsData();
  }, []);

  const style1 = { width: "92%" };
  const style2 = { marginLeft: "30px" };
  const style3 = { marginTop: "30px" };

  return (
    <>
      <Swiper className="home-slider" style={{ width: "70%" }}>
        <SlideNextButton />
        <SlidePrevButton />
        {listOfSliders.length > 0 && listOfSliders.map((data) => (
          <SwiperSlide>
            <img
              src={`https://coreyadmin.kgmmedia.co.uk/${data.main_slider_image}`}
              className="sliderImg img-fluid rounded-5 "
              style={{ ...style1, ...style2, ...style3 }}
              alt=""
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
}

export default Slider;
