import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import calender from "../assets/images/icons/calendar.svg";
import axios from "axios";
import Moment from "moment";

function DevBlogsCard(props) {
  // console.log(props);

  const [listOfBlogs, setlistOfBlogs] = useState([]);

  useEffect(() => {
    const getBlogsData = async () => {
      const { data } = await axios.get("https://coreyadmin.kgmmedia.co.uk/getBlogs");
      console.log(data);
      console.log(props.parentToChild);
      if (props.parentToChild === "All Post") {
        setlistOfBlogs(data);
      } else {
        let filterData = data.filter(
          (x) => x.game_category_id === props.parentToChild
        );
        setlistOfBlogs(filterData);
      }
    };
    getBlogsData();
  }, [props.parentToChild]);

  // const data = [
  //   {
  //     id:'1',
  //     title: "Jumanji World",
  //     desc: " Will you play the game, or will the game play you? Find out in JUMANJI: The Video Game, Coming November 15th to PlayStation4.",
  //     img: require('../assets/images/jumanji.png'),
  //     date: ' Dec 4, 2019'
  //   },
  //   {
  //     id:'2',
  //     title: "Jumanji World2",
  //     desc: " Will you play the game, or will the game play you? Find out in JUMANJI: The Video Game, Coming November 15th to PlayStation4.",
  //     img: require('../assets/images/shadowFIght3.png'),
  //     date: ' Dec 5, 2019'
  //   },
  //   {
  //     id:'3',
  //     title: "Jumanji World2",
  //     desc: " Will you play the game, or will the game play you? Find out in JUMANJI: The Video Game, Coming November 15th to PlayStation4.",
  //     img: require('../assets/images/shadowFIght3.png'),
  //     date: ' Dec 6, 2019'
  //   },
  // ];
  return (
    <>
      {listOfBlogs.length > 0 && listOfBlogs.map((d) => (
        <>
          <Link to={`/devblogsDetails/${d.blog_id}`} className="devBlog_card_a">
            <div className="d-flex flex-column flex-md-row gap-4 devBlog_card">
              <img
                src={`https://coreyadmin.kgmmedia.co.uk/${d.thumbnail_image}`}
                className="devBlog_image"
                alt=""
              />
              <div>
                <button>Latest Blog</button>
                <h1 className="title mt-3 mb-2 text-dark">{d.blog_title}</h1>
                <p className="desc">{d.blog_thumbnail_description}</p>
                <p className="date">
                  <img src={calender} alt="" />{" "}
                  {Moment(d.blog_release_date).format("MMM D, YYYY")}
                </p>
              </div>
            </div>
          </Link>
        </>
      ))}
    </>
  );
}

export default DevBlogsCard;
