import React, { useEffect, useState } from "react";
import Layout from "../component/Layout/Layout";
// import img from "../assets/images/blog_details_image.png";
import calender from "../assets/images/icons/calendar.svg";
import { useParams } from "react-router-dom";
import axios from "axios";
import Moment from "moment";

function DevBlogDetails() {
  const { id } = useParams();

  const [singleBlog, setSingleBlog] = useState({});
  const [blog_keywords, setBlogKeywords] = useState("");

  useEffect(() => {
    const getBlogsData = async () => {
      const { data } = await axios.get(
        `https://coreyadmin.kgmmedia.co.uk/getsingleblog/${id}`
      );
      setSingleBlog(data);
      let string = data.blog_keywords.slice(1, -1);
      setBlogKeywords(string.split(", "));
      console.log(data);
    };
    getBlogsData();
  }, [id]);

  return (
    <Layout>
      <div className="container my-4 my-md-5 devBlogDetails">
        <img
          src={`https://coreyadmin.kgmmedia.co.uk/${singleBlog.main_image}`}
          className="w-100"
          alt=""
        />
        <h1 className="title mt-5 mb-4">{singleBlog.blog_title}</h1>
        <p className="date">
          <img src={calender} alt="" />{" "}
          {(Moment(singleBlog.blog_release_date).format("MMM D, YYYY"))}
        </p>
        <>
          {blog_keywords.length > 0 && (
            <div className="d-flex flex-wrap gap-3 my-4">
              <>
                {blog_keywords.map((keyword) => (
                  <>
                    <button className="btn_two px-3 px-md-5">{keyword}</button>
                  </>
                ))}
              </>
            </div>
          )}
        </>
        <p className="desc">{singleBlog.blog_main_description}</p>
      </div>
      <div className="p-5"></div>
    </Layout>
  );
}

export default DevBlogDetails;
